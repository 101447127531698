
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="row justify-content-md-center mt-4">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>
                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center text-end">
                        ชื่อ-สกุลลูกค้า:
                      </label>
                      <b-form-input
                        v-model="filter.customer"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <label for="branchId " class="d-inline align-items-center"
                        >วันที่</label
                      >
                      <date-picker
                        v-model="filter.date"
                        :first-day-of-week="1"
                        format="YYYY-MM-DD"
                        value-type="format"
                        lang="en"
                      ></date-picker>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <label for="cusSrcId" class="d-inline align-items-center">
                        รุ่นรถ:</label
                      >
                      <multiselect
                        id="cusSrcId"
                        v-model="filter.modelId"
                        :options="modelOption"
                        :show-labels="false"
                        label="nameTh"
                        track-by="nameTh"
                        @search-change="getModel()"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="cusSrcId"> ทีม:</label>
                      <multiselect
                        id="cusSrcId"
                        v-model="filter.teamId"
                        :options="teamOption"
                        :show-labels="false"
                        label="name"
                        track-by="teamId"
                        @search-change="getTeam($event)"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="cusSrcId"> ที่ปรึกษาการขาย:</label>
                      <multiselect
                        id="cusSrcId"
                        v-model="filter.userId"
                        :options="userOption"
                        :show-labels="false"
                        label="nameTh"
                        track-by="userId"
                        :custom-label="customLabel"
                        @search-change="getUser($event)"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                    </div>
                  </div>
                  <div class="col-12 text-end">
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent
                    >&nbsp;&nbsp;&nbsp;&nbsp;

                    <router-link
                      :to="{ name: 'add-branchTestDrive' }"
                      class="btn btn-primary"
                    >
                      <i class="mdi mdi-plus me-1"></i>เพิ่มข้อมูล
                    </router-link>
                  </div>
                  <!-- <div class="row float-end"></div> -->
                </div>
              </div>
            </div>
            <div class="row justify-content-md-center mt-2">
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-12 col-md-12">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        แสดงผล&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          @input="handlePageChange"
                        ></b-form-select
                        >&nbsp;รายการ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <tableData :fields="fields" :items="rowData">
                <template #cell(index)="rowData" v-if="this.currentPage > 1">
                  {{ rowData.index + 1 + (currentPage * perPage - perPage) }}
                </template>
                <template #cell(index)="rowData" v-else>
                  {{ rowData.index + 1 }}
                </template>
                <template #cell(testDriveDate)="rowData">
                  <changeDate :date="rowData.item.testDriveDate"></changeDate>
                </template>
                <template #cell(status)="rowData">
                  <span
                    class="badge bg-warning font-size-12 ms-2"
                    v-if="rowData.item.status === 'DFT'"
                  >
                    ร่าง
                  </span>
                  <span
                    class="badge bg-success font-size-12 ms-2"
                    v-if="rowData.item.status === 'SUBMIT'"
                  >
                    บันทึกแล้ว
                  </span>
                </template>
                <template #cell(action)="rowData">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                        :to="{
                          name: 'edit-branchTestDrive',
                          params: { testId: rowData.item.testIdBase64 },
                        }"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>

                      <a
                        v-if="rowData.item.status != 'SUBMIT'"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="alert(rowData.item.testId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i> </a
                      >&nbsp;
                    </li>
                  </ul>
                </template>
              </tableData>

              <br />

              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col-md-12">
                    <div class="align-items-center">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </div>
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent.vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
// import branchComponent from "@/components/branchComponent.vue";
import ChangeDate from "@/components/changeDateComponent";

export default {
  page: {
    title: appConfig.testDrive,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    btnComponent,
    Multiselect,
    DatePicker,
    ChangeDate,
  },

  data() {
    return {
      loading: undefined,

      title: appConfig.testDrive,
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: appConfig.testDrive,
          active: true,
        },
      ],
      // partId: this.$route.params.partId,
      model: [],
      totalPage: "",
      branchId: [],
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      salaryLabel: "",
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,001-30,000", id: 1 },
        { nameTh: "30,001-50,000", id: 2 },
        { nameTh: "50,001-100,000", id: 3 },
        { nameTh: "100,000 บาทขึ้นไป", id: 4 },
      ],
      userOption: [],
      teamOption: [],
      filter: {
        customer: "",
        date: "",
        branchId: "",
        modelId: "",
        teamId: "",
        userId: "",
      },
      occOption: [],
      statusOption: [
        { nameTh: "ร่าง", id: "DFT" },
        { nameTh: "เสร็จสิ้น", id: "SUBMIT" },
      ],
      modelOption: [],
      branchIdSearch: "",
      localDataBranchId: [],
      filterOn: [],
      localData: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      statusLabel: "",
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "customerNameTh",
          label: "ชื่อ-สกุลลูกค้า",
          sortable: true,
        },
        {
          key: "testDriveDate",
          label: "วันที่",
          sortable: true,
        },
        {
          key: "modelNameTh",
          label: "รุ่นรถ",
          sortable: true,
        },

        {
          key: "status",
          label: "สถานะ",
          sortable: true,
        },
        {
          key: "createdBy",
          label: "ที่ปรึกษาการขาย",
          sortable: true,
        },
        {
          key: "branchName",
          label: "สาขา",
          sortable: true,
        },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
    };
  },

  computed: {

  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      // if (user.branchId == item.branchId) {
      //   this.branchIdSearch = {
      //     branchId: item.branchId,
      //     nameTh: item.nameTh,
      //   };
      // }
    });

    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    // Set the initial number of items
    this.getData();
    this.getModel();
    this.getUser();
    this.getTeam();
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    print(Data) {
      this.data = Data;
      this.$router.push({
        name: "add-contactJob",
        params: {
          data: this.data.item,
        },
      });
    },
    customLabel({ nameTh, familyName }) {
      return `${nameTh}  ${familyName != null ? familyName : ""}`;
    },
    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranchIdUser;
      } else {
        this.localDataBranchIdUser = [this.branchIdSearch.branchId];
      }

      this.getData();
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    getModel: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/test-drive/vehicle-model", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchId
              ? this.branchId
              : this.branchIdSearch.branchId,
          },
        })
        .then((response) => {
          this.modelOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getTeam: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/test-drive/dealer-team", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.branchIdSearch !== null
                ? this.branchIdSearch.branchId
                : this.branchId,
            name: event,
          },
        })
        .then((response) => {
          this.teamOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getUser: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/test-drive/dealer-user", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.branchIdSearch !== null
                ? this.branchIdSearch.branchId
                : this.branchId,
            name: event,
          },
        })
        .then((response) => {
          this.userOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getData: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/test-drive", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranchIdUser,
            customer: this.filter.customer,
            modelId: this.filter.modelId ? this.filter.modelId.modelId : "",
            testDriveDate: this.filter.date,
            teamId: this.filter.teamId ? this.filter.teamId.teamId : "",
            userId: this.filter.userId ? this.filter.userId.userId : "",
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    delete: function (rowData) {
      this.data = rowData;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/test-drive/delete", {
          data: {
            testId: this.data,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getData();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowData) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.delete(rowData);
          }
        });
    },
  },
  middleware: "authentication",
};
</script>
